@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  align-items: center;
  color: #8c8c8c;
  @include mixins.transition(color);
}

.link {
  color: #8c8c8c;
  &:visited {
    color: #8c8c8c;
  }
  &:hover {
    color: darken(#8c8c8c, 15);
  }
}

.divider {
  margin: 0 7px;
}

.main {
  margin-bottom: 70px;
  .homeLink {
    color: colors.$color-text-2;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    &:visited {
      color: colors.$color-text-2;
    }
  }

  .homeIcon {
    margin-right: 4px;
    margin-bottom: 3px;
  }
}

.taxpayer {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0;

  .homeIcon {
    display: none;
  }

  .link {
    color: rgba(0, 0, 0, 0.8);
    &:visited {
      color: rgba(0, 0, 0, 0.8);
    }
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .divider {
    margin: 0 6px;
  }

  .dot {
    display: block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

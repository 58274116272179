@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  margin-top: 4px;
  position: relative;

  .label {
    color: colors.$color-gray-8;
  }

  .count {
    margin-left: 8px;
  }

  &.noMargin {
    margin: 0;
  }

  .clearButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: colors.$color-gray-5;
    width: 18px;
    height: 18px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}

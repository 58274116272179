@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.input {
  min-width: 200px;
}

.T1 {
  &.input {
    @include mixins.input();
    &.output {
      background-color: #fff;
      color: colors.$color-gray-8;
    }

    &.hasValue:not(:disabled):not([readOnly]) {
      background: colors.$color-light-purple;
    }
  }
}

.T2 {
  &.input {
    @include mixins.input-t2();
    height: 64px;
    padding: 20px 12px 4px;
    &.output {
      background-color: #fff;
      color: colors.$color-gray-8;
      border: none;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23c1c4ce' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }

    &.hasValue:not(:disabled):not([readOnly]):not(.error) {
      background: white;
    }

    &[readOnly],
    &:disabled {
      background-color: colors.$color-gray-3;
    }
  }

  &.productTableInput {
    min-width: auto;
    border-radius: 0;
    border-color: transparent;
    font-size: 14px;
    line-height: 19px;
    height: 29px;
    padding-left: 9px;
    padding-right: 9px;

    &:read-only {
      background: #f6f7fc;
    }

    &.error {
      background: transparentize($color: colors.$color-danger, $amount: 0.95);
      color: #1a223b;
    }
  }

  &.material {
    border: 3px solid green;
  }
}

.fullWidth {
  width: 100%;
}

.wrapper {
  position: relative;
  display: inline-block;

  .innerLabel {
    position: absolute;
    z-index: 5;
    transform: translateY(-50%);
    left: 12px;
    top: 50%;
    color: rgba(26, 34, 59, 0.7);
    font-size: 16px;
    cursor: text;
    transition: all 0.15s ease-in;
    pointer-events: none;
  }

  .input:focus:not(:disabled):not([readOnly]) ~ .innerLabel,
  .input.hasValue ~ .innerLabel,
  input:-webkit-autofill ~ .innerLabel {
    top: 18px;
    font-size: 13px;
  }

  .iconWrapper {
    position: absolute;
    height: 100%;
    width: 40px;
    left: 0;
    top: 0;
    user-select: none;

    &.clickable {
      cursor: pointer;
    }
  }

  .icon {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    color: rgba(26, 34, 59, 0.7);
  }

  &.withIcon-left {
    .iconWrapper {
      left: 0;
    }

    .input {
      padding-left: 36px;
    }
  }

  &.withIcon-right {
    .iconWrapper {
      left: auto;
      right: 0;
    }

    .input {
      padding-right: 36px;
    }
  }

  .input {
    width: 100%;
    min-width: auto;
  }

  .suffix {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: calc(100% + 10px);
  }
}

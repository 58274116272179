@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: colors.$color-gray-5;
  text-align: center;
}

.color-YELLOW {
  background-color: colors.$color-yellow-5;
}

.color-GREEN {
  background-color: colors.$color-green-5;
}

.color-BLUE {
  background-color: colors.$color-blue-5;
}

.color-RED {
  background-color: colors.$color-red-5;
}

.color-TRANSPARENT {
  background-color: transparent;
  border: 1px solid colors.$color-gray-5;
}

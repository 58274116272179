@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 1200px;

  .content {
    min-height: 200px;
  }
}

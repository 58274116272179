@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .linksRow {
    margin-bottom: 22px;
    display: flex;
  }

  .oldVersionLink {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: auto;
    margin-right: 24px;
    &:visited {
      color: rgba(0, 0, 0, 0.8);
    }
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .filters {
    margin-bottom: 16px;
  }

  .pagination {
    padding: 8px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(26, 34, 59, 0.16);
  }

  .paginationLabel {
    color: rgba(26, 34, 59, 0.55);
    font-size: 14px;
    margin-left: 12px;
  }

  .inputPagination {
    margin-left: auto;
    flex-shrink: 0;
  }

  .header {
    display: flex;
    // justify-content: flex-end;
  }

  .headerButtons {
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px;
    padding-top: 4px;
    margin-left: auto;
  }

  .headerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    font-size: 14px;
    white-space: nowrap;

    .headerButtonIcon {
      left: 20px;
      width: 22px;
      height: 22px;
    }

    &.variant-icon {
      width: 32px;

      .headerButtonIcon {
        left: 50%;
      }
    }
  }

  .headerButton + .headerButton {
    margin-left: 8px;
  }

  .loader {
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 280px;
    padding-top: 170px;
    background: rgba(255, 255, 255, 0.2);
  }

  .tableLoader {
    @include mixins.coverdiv();
    padding: 1.5em 0 0;
    background: rgba(255, 255, 255, 0.2);
  }

  .actionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin-bottom: 4px;
  }

  .actionButtons {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 220px);
    .actionButtonsInner {
      display: flex;
      overflow: hidden;
    }
    .divider {
      height: 20px;
      width: 1px;
      background-color: #1a223b29;
      margin-right: 8px;
    }

    .moreActionsMenu {
      position: relative;
    }

    .moreButtons {
      position: absolute;
      right: 0;
      top: 120%;
      z-index: 100;
      width: max-content;
      min-width: 200px;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 8px 16px 0px rgba(38, 38, 38, 0.16), 0px 1px 2px 0px rgba(38, 38, 38, 0.04);
    }

    .moreButton {
      height: 36px;
      padding: 0 16px 0 8px;
      list-style: none;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(26, 34, 59, 0.08);
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: colors.$color-gray-2;
      }
    }

    .moreButton:last-child {
      border-bottom: none;
    }
  }

  .actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    font-size: 13px;
    white-space: nowrap;
    border-radius: 4px;
    padding: 0 8px 0 28px;
    flex-shrink: 0;
    margin-right: 8px;

    .actionButtonIcon {
      left: 14px;
      width: 18px;
      height: 18px;
    }

    &.variant-icon {
      width: 24px;
      padding: 0;

      .actionButtonIcon {
        left: 50%;
        width: 16px;
        height: 16px;
      }
    }
  }

  .tableTabs {
    margin-right: auto;
  }

  .noResult {
    height: 500px;
    position: relative;
    background-image: url('../../../../assets/images/journal_error.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    border-width: 0.5px 1px 0.5px 0.5px;
    border-style: solid;
    border-color: rgba(26, 34, 59, 0.16);
    border-radius: 0px 0px 8px 8px;
  }

  .errorLabel {
    position: absolute;
    font-size: 34px;
    font-weight: 300;
    left: 160px;
    top: 165px;
    color: colors.$color-red-6;
  }

  .errorMessage {
    position: absolute;
    font-size: 18px;
    font-weight: 400;
    left: 162px;
    top: 210px;
    width: 500px;
    color: colors.$color-red-6;
  }

  .emptyMessage {
    position: absolute;
    font-size: 36px;
    font-weight: 300;
    left: 160px;
    top: 180px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding: 14px 30px;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  .userInfo {
    margin-left: 24px;
  }

  .langToggle {
    margin-left: 50px;
  }

  .armLink {
    display: block;
    margin-right: 24px;
  }

  .buttonlink + .buttonlink {
    margin-left: 8px;
  }

  .button {
    width: 34px;
    height: 34px;
    display: block;

    .buttonIcon {
      width: 16px;
      height: 16px;
    }

    .badge {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 20;
      transform: translate(50%, -50%);
      background-color: colors.$color-red-5;
      box-shadow: none;
      font-weight: 700;
    }
  }

  .armInvite {
    margin-right: 8px;
  }

  .armInviteIcon {
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      width: 18px;
      height: 18px;
    }

    30% {
      width: 22px;
      height: 22px;
    }

    100% {
      width: 18px;
      height: 18px;
    }
  }
}

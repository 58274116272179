@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 40px;
}

h2 {
  margin: 0 0 24px;
}

h2:not(:first-child) {
  margin-top: 40px;
}

.contentHeader {
  border-bottom: 1px solid rgba(26, 34, 59, 0.16);
  padding: 20px;
  h2 {
    margin: 0 auto;
    text-align: center;
    white-space: pre;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.content {
  border-bottom: 1px solid rgba(26, 34, 59, 0.16);
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}

.signArea {
  padding: 20px;
  display: flex;

  button + button {
    margin-left: 24px;
  }

  button:first-child {
    flex-grow: 1;
  }

  .signedButton:disabled,
  .signedButton:disabled:hover {
    background-color: #34c759;
    border-color: #34c759;
    color: #fff;

    &:focus {
      outline: 1px solid #{transparentize(#000, 0.8)};
    }
  }
}

.agreeSection {
  margin-top: 24px;
}

.errorMessage {
  display: block;
  margin-top: 2px;
  font-size: 14px;
  color: colors.$color-danger;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;

  .loader {
    @include mixins.coverdiv();
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    padding-top: 0;
    padding-bottom: 15em;
  }

  .typeGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 20px;
  }

  .typeButton {
    position: relative;
    height: 48px;
    background: #f5f5f5;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: rgba(26, 34, 59, 0.7);
    text-align: left;
    padding: 0 16px;
    display: flex;
    align-items: center;
    user-select: none;
    @include mixins.transition(background, color);

    &.disabled {
      pointer-events: none;
      cursor: auto;
      color: rgba(26, 34, 59, 0.2);
    }
  }

  .bailiff {
    grid-column: 1 / span 2;
  }

  .typeIcon {
    position: relative;
    width: 34px;
    flex-shrink: 0;
    svg {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }

  .typeActiveIcon {
    position: absolute;
    right: 6px;
    opacity: 0;
    color: #ffffff;
    @include mixins.transition(opacity);
  }

  .typeActive {
    background: #34c759;
    color: #ffffff;
    .typeActiveIcon {
      opacity: 1;
    }
    &.disabled {
      color: #ffffff;
    }
  }

  .checkboxes {
    display: flex;
    margin-top: 40px;
  }

  .field {
    margin: 0;
  }

  .field + .field {
    margin-left: 80px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  resize: none;
}

.T1 {
  @include mixins.input();
  height: initial;
  &.output {
    background-color: #fff;
    color: colors.$color-gray-8;
  }
  &.error {
    background-color: #fff;
    color: colors.$color-red-5;
  }
}

.T2 {
  @include mixins.input-t2();
  height: initial;
  &.output {
    background-color: #fff;
    color: colors.$color-gray-8;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23c1c4ce' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  }
}

.fullWidth {
  width: 100%;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 400px;
  min-height: 300px;

  .content {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

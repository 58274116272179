@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;
  width: 450px;
  min-height: 57px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  padding: 16px 24px 16px 64px;
  color: colors.$color-text;
  border: 1px solid transparent;

  .icon {
    position: absolute;
    top: 16px;
    left: 24px;
  }

  &.error {
    background-color: #fff2f0;
    border-color: #ffccc7;
  }

  &.info {
    background-color: #e6f7ff;
    border-color: #91d5ff;
  }

  &.success {
    background-color: #f6ffed;
    border-color: #b7eb8f;
  }

  &.warning {
    background-color: #fffbe6;
    border-color: #ffe58f;
  }

  &.flat {
    display: flex;
    text-shadow: none;
    border: none;
    border-radius: 8px;
    text-align: left;
    padding: 16px 8px 16px 40px;
    color: #1a223b;
    font-size: 14px;
    line-height: 16px;
    box-shadow: none;

    h3 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin: 0 0 8px;
    }

    h4 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin: 20px 0 0;
    }

    .icon {
      top: 14px;
      left: 10px;
    }

    &.warning {
      background-color: #fff4e5;
    }

    &.info {
      background-color: #e5f3fb;
    }

    &.success {
      background-color: #e7f6e4;
    }

    &.error {
      background-color: #ffebea;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  // width: 100%;
  position: relative;

  .inner {
    &.scrollable {
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 200px;
    }
  }

  table {
    // min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 14px;
    line-height: 20px;
    width: 100px;
    overflow: visible;
  }

  tr {
    // display: flex;
    // align-items: center;
    // min-height: 46px;
  }

  td,
  th {
    border: 1px solid rgba(26, 34, 59, 0.16);
    height: 30px;
    padding: 5px 10px 4px;
    text-align: left;
  }

  th {
    position: relative;
    color: rgba(26, 34, 59, 0.7);
    background: #e9ebf2;
    text-transform: uppercase;
    user-select: none;
    font-size: 12px;
    vertical-align: top;

    &.required {
      &::after {
        content: '*';
        font-size: 17px;
        font-weight: bold;
        position: relative;
        top: 0px;
        left: 2px;
        color: colors.$color-red-5;
      }
    }
  }

  td {
    position: relative;
    white-space: nowrap;
  }

  .totalLabelTd {
    padding-left: 20px;
  }

  .cellContent {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .errorCell {
    background: transparentize($color: colors.$color-danger, $amount: 0.95);
    box-shadow: inset 0 0 1px 1px red;
  }

  .errorIcon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
  }

  .numberTr {
    th {
      text-align: center;
    }
  }

  .checkCell {
    padding: 2px 0 0;
    text-align: center;
  }

  th.checkCell {
    padding-top: 5px;
  }

  .selectedRow {
    td {
      background-color: #dddbfc;
    }
  }

  .formTd {
    padding: 0;
    overflow: visible;
  }

  .formControl {
    height: 29px;
  }

  .controlError {
    background: transparentize($color: colors.$color-danger, $amount: 0.95);
    // box-shadow: inset 0 0 1px 1px red;
    border: 1px solid red;
  }
}

.headerButtons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 4px;
}

.headerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: rgba(26, 34, 59, 0.7);
}

.headerButton + .headerButton {
  margin-left: 10px;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 100%;

  .header {
    padding: 31px 0 0 20px;
  }

  .logoLink {
    display: block;
  }

  .navigation {
    margin-top: 30px;
  }

  .logout {
    margin-top: 24px;
  }
}

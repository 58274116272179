@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  opacity: 1;
  @include mixins.transition(opacity);
  .newsItem {
    display: block;

    .date {
      font-size: 13px;
      font-weight: 700;
      color: rgba(26, 34, 59, 0.7);
      margin: 0;
    }
    .title {
      font-size: 20px;
      line-height: 26px;
      color: colors.$color-text-2;
      font-weight: 700;
      margin: 0;
      margin-bottom: 20px;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid #8986b2;
    }
    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .newsLink {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 30px;
    display: block;
    color: colors.$color-text-2;
    &:hover {
      color: lighten(colors.$color-text-2, 10%);
    }
  }
}

.transparent {
  opacity: 0;
}

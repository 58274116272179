@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  color: colors.$color-danger;
  width: 22px;
  height: 22px;
  position: relative;
  z-index: 10;

  .icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .tooltipContainer {
    display: none;
    position: absolute;
    width: 300px;
    transform: translateY(-50%);
    top: 50%;
    left: calc(100% + 4px);
    pointer-events: none;
  }

  .tooltip {
    border-radius: 8px;
    border: 1px solid rgba(255, 66, 54, 0.5);
    background: #fff;
    padding: 12px;
    font-size: 12px;
    color: colors.$color-text-2;
    width: fit-content;
    max-width: 100%;
    box-shadow: 0px -2px 16px 0px rgba(26, 34, 59, 0.12), 0px -1px 12px 0px rgba(26, 34, 59, 0.07);
    cursor: pointer;
    pointer-events: all;
    z-index: 10;
    white-space: normal;
  }

  &:hover,
  &.showTooltip {
    .tooltipContainer {
      display: block;
    }
  }

  &.hideTooltip {
    .tooltipContainer {
      display: none;
    }
  }

  &.small {
    width: 18px;
    height: 18px;
    .tooltipContainer {
      left: calc(100% + 2px);
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.withFlag {
  display: inline-flex;
  align-items: center;
  .flag {
    margin-right: 5px;
  }
}

.capitalize {
  text-transform: capitalize;
}

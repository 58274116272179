@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .headerButton + .headerButton {
    margin-left: 8px;
  }

  .filters {
    margin-bottom: 16px;
  }

  .treeWrapper {
    position: relative;
  }

  .loader {
    align-items: flex-start;
    padding-top: 64px;
  }
}

.control {
  width: 450px;
}

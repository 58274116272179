@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: vars.$nav-aside-max;
  @include mixins.transition(padding);

  a + a {
    margin-left: 24px;
  }

  a:first-of-type {
    margin-left: auto;
  }

  &.minimized {
    padding-left: vars.$nav-aside-min;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid transparent;
  background-color: transparent;
  text-align: center;
  height: 40px;
  padding: 0 14px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  z-index: 3;
  overflow: hidden;
  @include mixins.transition(background-color, color, border);
  outline: none;
  user-select: none;

  &.loading {
    cursor: auto;
  }

  &:not(.loading) {
    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      background-color: #f5f5f5;
      border: 1px solid colors.$color-gray-4;
      // box-shadow: inset 0 0 0px 1px #d9d9d9;
      color: #bfbfbf;
      cursor: auto;
    }
  }

  &:focus {
    outline: 1px solid #{transparentize(colors.$color-primary, 0.8)};
  }

  .loaderOverlay {
    padding: 0;
    position: absolute;
    .loader {
      transform: scale(80%);
      top: -2em;
      color: colors.$color-gray-4;
    }
  }
}

.fullWidth {
  width: 100%;
}

.icon {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 50%;
}

.iconLeft {
  padding-left: 40px;
  .icon {
    left: 22px;
    transform: translate(-50%, -50%);
  }
}

.iconRight {
  padding-right: 40px;

  .icon {
    transform: translate(50%, -50%);
    right: 22px;
  }
}

.primary,
.primary-upper {
  background: colors.$color-secondary;
  border-color: colors.$color-secondary;
  color: #262626;
  font-weight: 400;

  &.loading {
    background-color: lighten(colors.$color-secondary, 4);
  }

  &:hover {
    background-color: lighten(colors.$color-secondary, 2);
  }
  &:active {
    background-color: #bfa561;
  }
}

.secondary,
.secondary-upper {
  background-color: #f5f5f5;
  border: none;

  &:hover {
    background-color: #f3f3f3;
    border: none;
  }
  &:active {
    background-color: #e8e8e8;
    border: none;
  }
  &:focus {
    outline: 1px solid #{transparentize(colors.$color-primary, 0.9)};
  }
}

.primary-upper,
.secondary-upper {
  padding: 2px 20px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.tertiary {
  background-color: colors.$color-primary-dark-2;
  color: #fff;

  &:hover {
    background-color: lighten(colors.$color-primary-dark-2, 4);
  }
  &:focus {
    outline: 1px solid #{transparentize(colors.$color-primary-dark-2, 0.6)};
  }
}

.outlined {
  border: 1px solid colors.$color-primary;
  color: colors.$color-primary;

  &:hover,
  &:active {
    border: 1px solid #{transparentize(colors.$color-primary, 0.3)};
    color: transparentize(colors.$color-primary, 0.1);
  }

  &:active {
    background-color: transparentize(colors.$color-primary, 0.8);
  }
}

.transparent {
  border: none;
  background-color: transparent;
}

.medium {
}

.xsmall {
  height: 24px;
  font-size: 14px;
}

.small {
  height: 36px;
  font-size: 16px;
}

.large {
  height: 56px;
  min-width: 160px;
  font-size: 16px;
  padding: 0 26px;
}

.fzSmall {
  font-size: 14px;
}

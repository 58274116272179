@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: minmax(200px, max-content);
  grid-auto-flow: row;
  grid-auto-columns: minmax(200px, max-content);
  grid-auto-rows: 50px;
  gap: 0 24px;

  &.compact,
  &.compactOutlined {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-auto-rows: auto;
    row-gap: 4px;

    & .checkboxLabel {
      flex-shrink: 1;
    }
  }

  &.compactOutlined {
    border: 1px solid colors.$color-gray-5;
    border-radius: 4px;
    padding: 10px 12px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  --border: 1px solid rgba(26, 34, 59, 0.24);
  --border-color-active: #ab9266;
  --dot-color: #{colors.$color-secondary};
}

.inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  border: var(--border);
  direction: ltr;
  background-color: #fff;
  border-radius: 50%;
  border-collapse: separate;
  transition: all 0.3s;

  &:after {
    position: absolute;
    top: 4px;
    left: 4px;
    display: table;
    width: 10px;
    height: 10px;
    background-color: var(--dot-color);
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
  }
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  touch-action: manipulation;
}

.input:focus + .inner,
.wrapper:hover .inner {
  border-color: var(--border-color-active);
  box-shadow: 0px 0px 4px #{transparentize(colors.$color-primary, 0.8)};
}

.input:checked + .inner {
  border-color: var(--border-color-active);

  &:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
}

.readOnly {
  .inner {
    border: 1px solid colors.$color-gray-5;
    background-color: colors.$color-gray-2;
  }

  .input:checked + .inner {
    background-color: colors.$color-gray-5;
    border-color: colors.$color-gray-5;
  }
}

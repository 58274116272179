@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 1200px;

  .content {
    padding-top: 24px;
    padding-bottom: 40px;
    max-height: 650px;
    overflow-y: auto;
  }
}

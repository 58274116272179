@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  & + & {
    margin-top: 8px;
  }

  &.childNode {
    padding-left: 14px;
  }

  .nodeLoader {
    position: absolute;
    @include mixins.coverdiv();
    margin: 0;
    padding: 0;
    border-radius: 8px;
    opacity: 0.7;

    .loaderDots {
      transform: translateY(-15px);
    }
  }

  > .header {
    display: flex;
    padding: 10px 10px 10px 34px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(26, 34, 59, 0.16);
    background: #f5f6f8;
    min-height: 40px;
    position: relative;
    cursor: pointer;
    @include mixins.shortTransition(background-color);
    // margin-bottom: 8px;

    .chevron {
      position: absolute;
      width: 7px;
      height: 12px;
      transform: translate(-50%, -50%);
      left: 20px;
      top: 50%;
      @include mixins.shortTransition(transform);
    }
  }

  > .child {
    display: none;
  }

  &.expanded {
    > .header {
      background-color: #e8e8e8;
      margin-bottom: 8px;

      .chevron {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    > .child {
      display: block;
    }
  }

  &.final {
    > .header {
      padding-left: 16px;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 600px;

  .content {
    padding: 24px;
  }

  .inner {
    border-radius: 8px;
    border: 1px solid var(--Divider, rgba(26, 34, 59, 0.16));
  }

  .info,
  .text {
    padding: 12px;
  }

  .info {
    border-bottom: 1px solid var(--Divider, rgba(26, 34, 59, 0.16));
  }

  .text {
    min-height: 145px;
  }

  .row {
    line-height: 20px;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    b {
      margin-right: 8px;
    }
  }
}

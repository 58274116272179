@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  height: 100%;
  background-color: colors.$color-primary-dark;

  .inner {
    display: flex;
    align-items: center;
    height: 100%;
    @include mixins.container-outer();
    padding-left: 18px;
  }

  .langToggle {
    margin-left: auto;
  }

  .langToggleButton {
    color: white;
  }
}

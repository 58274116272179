@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  background-color: white;
  border: 1px solid colors.$color-gray-5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 16px;
}

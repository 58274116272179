@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 32px;
  position: relative;
  display: inline-block;

  .flag {
    padding: 75% 0 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }
}

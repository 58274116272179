@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  align-items: center;
  line-height: 17px;

  .info {
    margin-left: 8px;
  }

  .name {
    font-weight: 500;
  }

  .tin {
    font-size: 12px;
  }
  .role {
    font-size: 12px;
    color: colors.$color-gray-7;
  }
}

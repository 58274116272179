@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  .groupPanel {
    display: grid;
    grid-template-columns: 250px auto;
    grid-template-rows: 70px auto;
    grid-template-areas:
      'groups .'
      'groups .';
    margin-bottom: 24px;

    .groups {
      grid-area: groups;
    }

    .topRow {
      white-space: nowrap;
    }

    .bottomRow {
    }

    .iinInput {
      display: inline-block;
    }

    .addButton {
      margin-left: 4px;
    }

    .fileInput {
      margin-left: 12px;
      width: 250px;
    }

    .iinTag {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  .content {
    width: 800px;
  }

  .themeSelect {
    width: 300px;
  }
}

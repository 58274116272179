@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 450px;

  .container {
    margin: 20px 24px;
    min-height: 100px;
  }

  .button + .button {
    margin-left: 8px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 500px;

  .content {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: block;

  .errorMessage {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    color: colors.$color-danger;
  }

  .errorIcon {
    color: colors.$color-danger;
    margin-left: 12px;
  }

  input,
  textarea {
    display: block;
  }
}

.inline {
  display: flex;
  align-items: center;
}

.label {
  flex-shrink: 0;
}

.innerFullWidth {
  flex-grow: 1;
}

.topMargin:not(:first-child) {
  margin-top: 8px;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  margin-left: 30px;
}

.textButton {
  -webkit-appearance: auto;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
  @include mixins.transition(color);
  color: colors.$color-primary-dark;
  display: flex;
  align-items: center;
  height: 25px;
  &:hover {
    color: lighten(colors.$color-primary-dark, 10);
  }
}

.logText {
  margin-top: 3px;
  margin-right: 2px;
}

.reversed {
  color: #d9b673;
  &:hover {
    color: lighten(#d9b673, 5);
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 500px;

  .groupLabel {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0 8px;
  }

  .allField {
    .groupLabel {
      padding-left: 0;
    }
  }

  .groupField {
    display: inline-block;
  }

  .permissionField {
    padding: 8px 0;
    position: relative;
    padding-right: 80px;
    line-height: 18px;
  }

  .accHeader {
    margin: 20px 0 8px;
  }

  .status {
    position: absolute;
    right: 0;
    font-weight: 600;
    font-size: 12px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

$filter-width: 300px;

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .filters {
    width: 300px;
    flex-shrink: 0;
  }

  .content {
    width: calc(100% - #{$filter-width});
    // min-width: none;
    padding: 24px;
  }

  .paginationRow {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .paginationSize {
    margin-left: auto;
  }

  .paginationInput {
    margin-left: 24px;
  }

  .paginationLabel {
    color: rgba(26, 34, 59, 0.55);
    font-size: 14px;
    margin-left: 12px;
  }

  .table {
    height: calc(100% - 46px);
  }

  .tableInner {
    max-height: 100%;
  }

  .loader {
    margin-top: 160px;
  }
}

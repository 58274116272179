@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 500px;
  overflow: auto;
  .content {
    padding-bottom: 110px;
    overflow: auto;
  }
}

.formField {
  margin-bottom: 12px;
  label {
    font-size: 16px;
  }
}

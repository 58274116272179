@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.form {
  margin-top: 40px;

  .control {
    height: 64px;
  }

  .links {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    a {
      color: colors.$color-primary;
    }
  }

  .button {
    display: block;
    margin-top: 24px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .actions {
    margin-top: 24px;
  }

  .table {
    margin-top: 8px;
  }
}

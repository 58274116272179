@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .filters {
    margin-bottom: 16px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding: 0 16px;

  .langToggle {
    margin-right: 8px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 400px;

  &.noHeader {
    padding-top: 28px;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 24px;
    min-height: auto;
    max-height: 400px;
    overflow: auto;
  }

  .button {
    min-width: 70px;
  }
}

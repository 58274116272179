@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

$disabled-color: #5b6176;

.wrapper {
  min-width: 100px;
  min-height: 36px;
  border: 1px solid #c1c4ce;
  border-radius: 6px;
  padding: 1px;

  .button {
    background-color: transparent;
    border: none;
    height: 34px;
    // min-width: 80px;
    font-weight: 400;
    padding: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    white-space: nowrap;
    @include mixins.transition(all);

    &:disabled {
      // background-color: #e9ebf2;
      color: lighten($disabled-color, 20);
    }
  }

  .button + .button {
    margin-left: 1px;
  }

  &.evenly {
    display: flex;

    .button {
      flex: 1 0 50px;
    }
  }

  .selected {
    background-color: colors.$color-secondary;
    color: #fff;
    &:hover {
      background-color: lighten(colors.$color-secondary, 3);
    }
    &:active {
      background-color: #bfa561;
    }
    &:disabled {
      background-color: lighten($disabled-color, 30);
      color: #fff;
    }
  }
}

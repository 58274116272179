@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding: 24px 32px;
  display: grid;
  grid-template-columns: 120px auto;
  gap: 4px 32px;
  line-height: 20px;
  cursor: pointer;
  @include mixins.transition(background-color);

  &:hover {
    background-color: #f1f2f6;
  }

  .date {
    grid-row-end: span 2;
  }

  .name {
    b {
      margin-right: 10px;
    }
  }

  .name + .name {
    margin-left: 20px;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(26, 34, 59, 0.7);

    br {
      display: none;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  cursor: pointer;
  align-items: center;
  display: flex;
  width: 174px;
  height: 74px;
  background-image: url('./images/logo_kgd_text_gold.svg');
  background-size: cover;
}

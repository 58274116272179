@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 400px;
  overflow-y: auto;
  .content {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

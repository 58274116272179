@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-bottom: 70px;
  .label {
    width: 300px;
    align-self: start;
    margin-top: 12px;
  }

  .footer {
    justify-content: flex-end;
  }

  .button + .button {
    margin-left: 8px;
  }

  .field:not(:first-child) {
    margin-top: 32px;
  }

  .control {
    width: 350px;
  }

  .divider {
    border-top: 1px solid colors.$color-gray-3;
    margin: 48px 0;
  }
}

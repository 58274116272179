@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  .input,
  .count {
    max-width: 270px;
  }

  &:not(:first-child) {
    margin-top: 4px;
  }
}

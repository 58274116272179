@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 100px;
  min-width: auto;
}

.T1 {
  width: 100px;
}

.T2 {
  width: 70px;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 600px;

  .content {
    padding-bottom: 12px;
    padding-top: 24px;
    max-height: 450px;
    overflow-y: auto;
  }

  .categorySelect {
    width: 66%;
  }

  .fileInput {
    width: 50%;
  }
}

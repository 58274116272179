@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  cursor: pointer;
  align-items: center;
  display: flex;
  // width: 220px;

  .logo {
    background-image: url('./images/logo_kgd.svg');
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    color: #fff;
    margin: 2px 0 0 4px;
    opacity: 1;
    visibility: visible;
    white-space: pre;
    @include mixins.transition(opacity, visibility);
  }

  &.minimized {
    width: 40px;
    .text {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.words {
  transition: 0.2s all;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.button {
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 48px;
  height: 48px;
  background-color: colors.$color-text-2;
  border-radius: 50%;
  padding: 0 0 1px;

  .buttonIcon {
    color: white;
  }
}

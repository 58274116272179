@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 700px;

  .content {
    padding: 24px;
  }

  .agreeSection {
    margin-top: 12px;
  }

  .agreementContent {
    height: 380px;
  }
}

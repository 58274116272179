@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 500px;
  .content {
    padding: 24px 24px 36px;
    max-height: calc(100vh - 200px);
    overflow: auto;
  }

  .field:not(:first-child) {
    margin-top: 12px;
  }

  .label {
    flex-shrink: 1;
  }
}

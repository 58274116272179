@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .taxpayerName {
    font-size: 24px;
    line-height: 32px;
    margin: 16px 0 0;
  }

  .bin {
    margin: 0;
  }

  .buttons {
    margin-top: 24px;
  }

  .table {
    margin-top: 24px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  height: 100%;
  font-size: 14px;

  .inner {
    height: 100%;
    padding-top: 40px;
    display: flex;
    @include mixins.container-outer();
  }

  .col {
    width: 50%;
  }

  h4 {
    margin: 0 0 16px;
    font-weight: 400;
  }

  .mail {
    display: block;
    color: colors.$color-secondary;
    text-decoration: underline;
  }

  .subText {
    display: block;
    font-size: 12px;
    color: colors.$color-gray-6;
  }
}

@use 'sass:meta';
@import 'styles/normalize.css';
@import 'styles/font-face.css';
@include meta.load-css('styles/util-classes');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.container {
  padding: 0 16px;
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.hidden {
  display: none;
}

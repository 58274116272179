@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

$thin-line-color: #e0e0e0;

.wrapper {
  padding-bottom: 96px;

  .headSection {
    height: 236px;
    max-width: 1100px;
    position: relative;

    .idField {
      position: absolute;
      right: 0;
      top: 0;
    }

    .qrCode {
      position: absolute;
      right: 0;
      top: 60px;
    }
  }

  .mainTitle {
    font-size: 46px;
    line-height: 54px;
    font-weight: 400;
    margin: 0;
  }

  .mainSubTitle {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  .section {
    padding-bottom: 96px;
  }

  .title {
    margin-bottom: 40px;
  }

  .subTitle {
    margin-bottom: 16px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    max-width: 1100px;
  }

  .gridLeftColumn {
    width: 400px;
  }

  .gridRightColumn {
    width: 500px;
    font-weight: 500;
  }

  .field {
    margin-top: 30px;
  }

  .fieldLabel {
    text-align: right;
    margin-right: 24px;
  }

  .table {
    margin-bottom: 32px;
  }

  .docTable {
    width: 1000px;
  }

  .addDocGrid {
    margin-top: 24px;
  }

  .addDocDate {
    width: 150px;
    margin-left: 52px;
    padding-top: 2px;
  }

  .addDocLinks {
    width: 300px;
  }

  .addDocClose {
    width: 24px;
  }

  .addDocButton {
    margin-top: 32px;
  }

  .addDocCloseButton {
    cursor: pointer;
    color: colors.$color-danger;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accordion {
    border: 1px solid $thin-line-color;
  }

  .accordion + .accordion {
    border-top: none;
  }

  .accordionHeader {
    height: 70px;
    font-size: 20px;
    font-weight: 500;
    padding: 16px;
  }

  .accordionIcon {
    margin-left: auto;
    width: 15px;
    height: 10px;
  }

  .attachment {
    padding: 16px;

    .attInner {
      border-radius: 4px;
      border: 1px solid $thin-line-color;
    }

    .attInner + .attInner {
      margin-top: 16px;
    }

    .attInnerHeader {
      padding: 16px;
      border-bottom: 1px solid $thin-line-color;
      font-size: 20px;
    }

    .attInnerBody {
      padding: 16px 16px;
    }

    .attTable {
      display: block;
      border: 1px solid #262626;
      border-top: none;
      border-left: none;
    }

    .attGrid {
      display: grid;
      width: 100%;
    }

    .attCell {
      padding: 8px;
      --border: 1px solid #262626;
      width: 100%;
      font-size: 16px;

      &.bb {
        border-bottom: var(--border);
      }

      &.bt {
        border-top: var(--border);
      }

      &.bl {
        border-left: var(--border);
      }

      &.br {
        border-right: var(--border);
      }

      span + span {
        margin-top: 2px;
      }
    }

    .attCellLabel {
      display: block;
      color: #595959;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }

    .attCellValue {
      display: block;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin: 24px 0 16px;
    }

    //ДТ приложение
    .gdtHeader {
      display: flex;
      align-items: center;

      .attCell {
        border: none;
        padding: 0 4px;
        height: 38px;
      }

      .attCell:first-child {
        width: 170px;
        margin-left: auto;
      }

      .attCell:nth-child(2) {
        width: 190px;
      }
    }

    .gdtBody {
      .accordion {
        border: none;

        & + .accordion {
          border-top: 1px solid $thin-line-color;
        }
      }

      .accordionHeader {
        font-size: 16px;
        font-weight: 400;
        min-height: 70px;
        height: auto;
        white-space: pre-line;
      }

      .gdtFullDesc {
        padding-right: 20px;
      }

      .accordionOpened {
        .accordionHeader {
          border-bottom: 1px solid $thin-line-color;
        }
      }
    }

    .gdtDocRow {
      // display: flex;
      span + span {
        margin-left: 8px;
      }

      & + .gdtDocRow {
        margin-top: 10px;
      }
    }

    .gdtChanges {
      border: 1px solid $thin-line-color;
      padding: 12px;
      border-radius: 4px;
      display: grid;
      gap: 12px;

      + .gdtChanges {
        margin-top: 8px;
      }
    }

    .gdtChangesRow {
      display: flex;
      justify-content: space-between;

      .attCellValue {
        white-space: pre-line;
      }
    }
  }
}

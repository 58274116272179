@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(26, 34, 59, 0.06), 0px -4px 4px rgba(26, 34, 59, 0.08);
  border-radius: 8px;
  color: colors.$color-text;
  padding: 56px 40px;
}

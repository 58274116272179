@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .row {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .column {
    width: 300px;
  }

  &.alignRight {
    .row {
      justify-content: flex-end;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  z-index: 3;
  @include mixins.transition(opacity, color);
  outline: none;
  color: colors.$color-gray-9;

  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 0.85;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    color: #bfbfbf;
    cursor: auto;
    pointer-events: none;
  }

  &:focus {
    // box-shadow: 0px 0px 4px #{transparentize(colors.$color-primary, 0.4)};
    outline: 1px solid #{transparentize(colors.$color-primary, 0.8)};
  }
}

.icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.T2 {
  border-radius: 6px;
  &.primary {
    background-color: colors.$color-secondary;
    &:disabled {
      background-color: transparent;
    }
  }

  &.tertiary {
    background-color: colors.$color-primary-dark-2;
    color: #fff;

    &:hover {
      opacity: 1;
      background-color: lighten(colors.$color-primary-dark-2, 4);
    }
    &:focus {
      outline: 1px solid #{transparentize(colors.$color-primary-dark-2, 0.6)};
    }
  }
}

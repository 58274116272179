@use 'sass:list';
@use 'sass:string';
@use 'sass:color';
@use 'colors';

// width
@mixin r($width) {
  @media only screen and (max-width: ($width + 'px')) {
    @content;
  }
}

@mixin rmin($width) {
  @media only screen and (min-width: ($width + 'px')) {
    @content;
  }
}

// height
@mixin rh($height) {
  @media only screen and (max-height: ($height + 'px')) {
    @content;
  }
}

@mixin rhmin($height) {
  @media only screen and (min-height: ($height + 'px')) {
    @content;
  }
}

$browser-context: 16; // Default

@mixin coverdiv() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@mixin transition($props...) {
  $result: ();

  @for $i from 1 through list.length($props) {
    $prop: list.nth($props, $i);
    $result: list.append($result, $prop);
    $result: list.append($result, ease-in-out 0.3s);

    @if $i != list.length($props) {
      $result: list.append(
        $result,
        string.unquote(
          $string: ',',
        )
      );
    }
  }

  transition: $result;
}

@mixin shortTransition($props...) {
  $result: ();

  @for $i from 1 through list.length($props) {
    $prop: list.nth($props, $i);
    $result: list.append($result, $prop);
    $result: list.append($result, ease-in-out 0.1s);

    @if $i != list.length($props) {
      $result: list.append(
        $result,
        string.unquote(
          $string: ',',
        )
      );
    }
  }

  transition: $result;
}

@mixin shadow1() {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

@mixin shadow2() {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

@mixin shadow3() {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

@mixin custom-scroll() {
  // overflow-y: scroll;

  /* firefox scroll */
  scrollbar-color: #6b767d rgba(255, 255, 255, 0);
  scrollbar-width: thin;

  /* Google scroll */
  &::-webkit-scrollbar {
    background-color: rgba(102, 102, 128, 0.1);
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(102, 102, 128);
    border-radius: 3px;
  }
}

@mixin input() {
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 4px 12px;
  color: colors.$color-gray-8;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  background-image: none;
  border: 1px solid colors.$color-gray-5;
  border-radius: 4px;
  outline: none;
  @include transition(all);
  flex-shrink: 0;

  &::placeholder {
    color: #bfbfbf;
  }

  &:hover:not(:disabled):not([readOnly]) {
    @include input-hover();
  }

  &:focus:not(:disabled):not([readOnly]) {
    @include input-focus();
  }

  &[readOnly],
  &:disabled {
    background-color: #f5f5f5;
    color: colors.$color-gray-7;
  }

  &.error {
    border: 1px solid colors.$color-danger;
    color: colors.$color-danger;

    &:hover,
    &:hover:not(:disabled):not([readOnly]) {
      border-color: colors.$color-danger;
    }

    &:focus,
    &:focus:not(:disabled):not([readOnly]) {
      border-color: colors.$color-danger;
      box-shadow: 0px 0px 4px #{color.adjust(colors.$color-danger, $alpha: -0.4)};
    }
  }

  &.hasValue:not(:disabled):not([readOnly]) {
    background: colors.$color-light-purple;
  }
}

@mixin input-focus {
  box-shadow: 0px 0px 4px #{color.adjust(colors.$color-primary, $alpha: -0.4)};
  border: 1px solid colors.$color-primary;
  outline: none;
}

@mixin input-hover {
  border: 1px solid colors.$color-primary;
}

@mixin modal-container {
  padding-right: 24px;
  padding-left: 24px;
}

@mixin container-outer {
  max-width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

@mixin container {
  padding: 0 16px;
}

@mixin container-guest {
  max-width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

@mixin input-t2() {
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 4px 12px;
  color: #1a223b;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c1c4ce;
  border-radius: 6px;
  outline: none;
  @include transition(all);

  &::placeholder {
    color: #bfbfbf;
  }

  &:hover:not(:disabled):not([readOnly]) {
    @include input-hover();
  }

  &:focus:not(:disabled):not([readOnly]) {
    @include input-focus();
  }

  &[readOnly],
  &:disabled {
    background-color: #e9ebf2;
    color: #5b6176;
    // border: 1px solid rgba(26, 34, 59, 0.24);
    // color: colors.$color-gray-7;
  }

  &.error {
    border: 1px solid colors.$color-danger;
    color: colors.$color-danger;

    &:hover,
    &:hover:not(:disabled):not([readOnly]) {
      border-color: colors.$color-danger;
    }

    &:focus,
    &:focus:not(:disabled):not([readOnly]) {
      border-color: colors.$color-danger;
      box-shadow: 0px 0px 4px #{color.adjust(colors.$color-danger, $alpha: -0.4)};
    }
  }
}

@mixin container-t2 {
  padding: 0 28px;
}

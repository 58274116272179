@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;
  width: 350px;
  max-height: 700px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow-y: auto;
}

.loader {
  border-radius: 8px;
}

.inner {
  padding: 0 16px 16px;
  text-align: left;

  .profile {
    position: relative;
    padding: 16px 0 16px 28px;
    border-bottom: 0.5px solid rgba(26, 34, 59, 0.24);
    cursor: pointer;
  }

  .profile:last-child {
    border-bottom: none;
  }

  .radio {
    position: absolute;
    left: 0;
    top: 16px;
    pointer-events: none;
  }

  .profileName {
    font-weight: 700;
    margin: 0;
  }

  .details {
    display: flex;
    color: colors.$color-gray-8;
    font-size: 13px;
    padding-top: 8px;

    .column:first-child {
      width: 120px;
    }

    p {
      margin: 0;
    }
  }
}

.modal {
  position: relative;
  max-height: 550px;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  overflow-y: auto;
  padding: 24px;

  .inner {
    padding: 0;
  }

  .profile {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    padding: 20px 20px 20px 50px;
  }

  .profile + .profile {
    margin-top: 16px;
  }

  .radio {
    left: 20px;
    top: 20px;
  }

  .details {
    .column:first-child {
      width: 150px;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 600px;
  font-size: 14px;
}

.body {
  @include mixins.modal-container();
  padding-top: 24px;
  padding-bottom: 16px;
}

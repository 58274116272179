@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  background: #ffffff;
  box-shadow: 0px -1px 12px rgba(26, 34, 59, 0.07), 0px -2px 16px rgba(26, 34, 59, 0.12);
  border-radius: 8px;
  width: 500px;

  .header {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(26, 34, 59, 0.16);
  }

  .content {
    display: none;
  }

  .icon {
    margin: 0 8px 0 0;
  }

  .contentInner {
    padding: 16px 16px 36px;
  }

  &.opened {
    .content {
      display: block;
    }

    .icon {
      margin-bottom: 3px;
    }
  }

  &.animated {
    .content {
      display: block;
      overflow: hidden;
      @include mixins.transition(all);
    }
    &.opened {
      .content {
        display: block;
      }
    }
  }
}

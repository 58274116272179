@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: block;
  background: #f5f6f6;
  border-radius: 4px;
  padding: 6px 8px;
  padding-bottom: 24px;
  position: relative;
  color: colors.$color-text-2;
  overflow: hidden;

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
    margin: 0;
    margin-bottom: 32px;
  }

  .date {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: rgba(26, 34, 59, 0.55);
    margin: 4px 0 0;
    border-top: 1px solid rgba(26, 34, 59, 0.16);
    width: 220px;
    position: absolute;
    bottom: 6px;
    left: 6px;
  }

  .snippet {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: rgba(26, 34, 59, 0.7);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
    margin-bottom: 16px;
  }
}

.second {
  .title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 24px;
  }
  .snippet {
    font-size: 16px;
    line-height: 24px;
  }
}

.third {
  .title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 24px;
  }
}

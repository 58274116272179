@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  @include mixins.container-guest();

  h3 {
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 6px;
  }

  .grid {
    display: grid;
    grid-template-columns: 792px 368px;
    grid-template-rows: auto;
    align-items: start;
    gap: 0 40px;
  }

  .card {
    border-radius: 24px;
    background: var(--Background, #f6f7fc);
    padding: 40px;

    p {
      font-size: 36px;
      line-height: 38px;
      font-weight: 700;
      margin: 0 0 32px 0;
    }

    a + a,
    button + a {
      margin-left: 16px;
    }
  }

  .regButton {
    &:hover {
      color: lighten(colors.$color-secondary, 5);
      border-color: lighten(colors.$color-secondary, 5);
    }
  }
}

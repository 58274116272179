@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 700px;

  .content {
    padding-bottom: 12px;
    max-height: 600px;
    overflow: auto;
  }

  .header {
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  background: url('../../../assets/hills.png'), colors.$color-primary;
  background-position: bottom;
  background-repeat: repeat-x;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1 0 auto;
    padding-bottom: 24px;
    width: 100%;
    background-color: #fff;
    color: colors.$color-text;
    @include mixins.transition(padding, color);
  }

  .header {
    height: 64px;
  }

  .footer {
    flex-shrink: 0;
    height: 250px;

    a {
      color: #fff;
      font-weight: 300;
      &:hover {
        color: #ddd;
      }
    }
  }
}

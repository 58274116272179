@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 4px;

  .section {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
  }

  .section:not(:last-child) {
    border-bottom: 1px solid colors.$color-gray-4;
  }

  .passwordSection {
    display: flex;
    justify-content: flex-end;
  }

  .accHeader {
    margin: 20px 0 8px;
  }

  .groupLabel {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-right: 16px;
  }

  .permissonLabel {
    font-size: 14px;
    line-height: 32px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;
  min-height: 200px;

  .filter {
    padding: 8px 20px;
  }

  .form {
    display: flex;
    gap: 8px;
  }

  .buttonsRow {
    padding: 8px 20px;
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }

    a {
      margin-left: auto;
    }

    .toJournalButton {
      height: 32px;
      font-size: 14px;
    }
  }
}

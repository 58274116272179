@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  font-size: 14px;
  line-height: 20px;
  color: colors.$color-gray-8;
  user-select: none;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 24px;
  max-width: 1000px;

  .title {
    margin-bottom: 16px;
  }

  .text {
    margin: 16px 0;
  }

  .container-T1 {
    @include mixins.container();
  }

  .container-T2 {
    @include mixins.container-t2();
  }
}

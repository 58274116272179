@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;

  .tab {
    position: relative;
    height: 48px;
    width: 220px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: rgba(26, 34, 59, 0.55);
    cursor: pointer;
    user-select: none;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: transparent;
    }
  }

  .activeTab {
    color: #2c2653;
    &:after {
      background-color: #2c2653;
    }
  }

  .tabIcon {
    margin-right: 10px;
  }
}

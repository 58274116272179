@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  & + & {
    margin-top: 8px;
  }

  &:first-child {
    > .header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &:last-child {
    > .header {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 0;
    }
  }

  &.childNode {
    padding-left: 14px;

    > .header {
      border-radius: 0;
    }
  }

  > .header {
    min-height: 48px;
    border: 1px solid #d9d9d9;
    background-color: #fafafa;
    padding: 12px 16px 12px 40px;
    position: relative;
    cursor: pointer;
    @include mixins.shortTransition(background-color);
    margin-bottom: 8px;

    .chevron {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 24px;
      top: 50%;
      height: 8px;
      @include mixins.shortTransition(transform);
    }
  }

  > .child {
    display: none;
  }

  &.expanded {
    > .header {
      background-color: #e8e8e8;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 8px;

      .chevron {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    > .child {
      display: block;
    }
  }

  &.final {
    > .header {
      padding-left: 16px;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: relative;

  .link {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 35px 0 25px;
    @include mixins.transition(padding, color, background);
    color: #fff;
    cursor: pointer;
  }

  &:hover {
    color: #ddd;
    background: rgba(255, 255, 255, 0.1);

    .subMenu {
      opacity: 1;
      visibility: visible;
      width: auto;
    }
  }

  .iconContainer {
    position: relative;
    flex-shrink: 0;
    display: block;
  }

  .icon {
    display: block;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    white-space: pre;
    opacity: 1;
    visibility: visible;
    @include mixins.transition(opacity, visibility);
  }

  .chevron {
    position: absolute;
    right: 15px;
    opacity: 1;
    visibility: visible;
    @include mixins.transition(opacity, visibility);
  }

  &.minimized {
    .link {
      padding-left: 35px;
    }

    .text {
      opacity: 0;
      visibility: hidden;
    }
    .chevron {
      opacity: 0;
      visibility: hidden;
    }
  }

  .subMenu {
    position: absolute;
    width: 0;
    max-height: 500px;
    background: colors.$color-secondary;
    top: 0;
    left: 100%;
    opacity: 0;
    visibility: hidden;
    @include mixins.transition(opacity, visibility);
    @include mixins.shadow3();
    overflow-x: hidden;
    overflow-y: auto;

    &.directionUp {
      top: auto;
      bottom: 0;
    }
  }

  &.T2 {
    .link {
      padding: 0 35px 0 15px;
      height: 48px;
    }

    .iconContainer {
      width: 28px;
      height: 28px;
      background: #2c2653;
      border-radius: 11px;
    }

    .icon {
      width: 16px;
      height: 16px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .text {
      margin-left: 20px;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .table {
    margin-top: 12px;
  }

  .buttons {
    margin-top: 24px;
    display: flex;

    button + button {
      margin-left: 8px;
    }
  }

  .actionButton + .headerButton {
    margin-left: auto;
  }
}

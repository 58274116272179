@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  position: absolute;
  transform: translateY(-50%);
  top: 40px;
  left: 20px;

  .title {
    margin: 0;
  }

  &.relative {
    top: -40px;
  }

  &.T2 {
    position: fixed;
    top: 30px;
    left: vars.$taxpayer-aside-width + 30px;
    h1 {
      font-weight: 700;
      font-size: 28px;
    }
    z-index: vars.$z-header-title;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.form {
  width: 340px;
  margin-top: 40px;
  .button {
    display: block;
    &:first-of-type {
      margin-top: 24px;
    }
  }

  .control {
    height: 64px;
  }

  .links {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    a {
      // color: #fff;
    }
  }
}

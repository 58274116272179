@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 700px;

  .content {
    position: relative;
    padding-bottom: 24px;
    padding-top: 24px;
    max-height: 600px;
    min-height: 200px;
    overflow: auto;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 24px;
  }

  .header {
  }
}

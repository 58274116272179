@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding: 24px;

  .form {
    position: relative;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .actionText {
      font-size: 20px;
      color: #a7b4c3;
      margin-right: 16px;
    }

    button + button {
      margin-left: 8px;
    }
  }

  .filters {
    margin-bottom: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 24px 24px;

    .content {
      padding: 0 0 24px 0;
      display: flex;

      .column {
        width: 25%;
      }

      .column + .column {
        margin-left: 24px;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 100px;
      }

      button + button {
        margin-left: 8px;
      }
    }
  }

  .contentContainer {
    position: relative;
    height: 470px;

    .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      @include mixins.transition(opacity, visibility);
    }

    .showContent {
      visibility: visible;
      opacity: 1;
    }

    .pictureContainer {
      margin-bottom: 24px;

      .picture {
        margin: 0 auto;
        display: block;
        visibility: visible;
        height: 400px;
      }
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
}

.header {
  display: flex;
  margin-bottom: 8px;

  .filterButtonWrapper {
    margin-left: auto;
    position: relative;
    padding-left: 8px;
  }

  .chevronIcon {
    width: 8px;
    height: 5px;
  }

  .badge {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 3px;
    left: 3px;
    z-index: 10;
  }
}

.body {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 24px 24px;
}

.content {
  padding: 0 0 24px 0;
  display: flex;
}

.column {
  width: 25%;
}

.column + .column {
  margin-left: 24px;
}

.gridWrapper {
  width: 100%;
}

.gridSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  align-items: end;
  gap: 12px 24px;

  // &:not(:first-child) {
  //   padding-top: 12px;
  // }
}

.gridDivider {
  height: 1px;
  background-color: colors.$color-gray-4;
  margin: 16px 0 12px;
}

.gridSectionExpandalbe {
  max-height: 0;
  overflow: hidden;
  @include mixins.transition(max-height, overflow);
  .gridSection {
    padding-top: 12px;
  }
}

.gridPaddingContainer {
  padding-bottom: 68px;
}

.expanded {
  .gridSectionExpandalbe {
    max-height: 450px;
  }
  &.overflowVisible {
    .gridSectionExpandalbe {
      overflow: visible;
    }

    .gridWrapper {
      width: 100%;
    }

    .gridSection {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-items: flex-end;
      gap: 12px 24px;
    }

    .gridDivider {
      height: 1px;
      background-color: colors.$color-gray-4;
      margin: 16px 0 12px;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;

  button {
    min-width: 100px;
  }

  button + button {
    margin-left: 8px;
  }

  .expandButton {
    width: 110px;
    justify-content: flex-start;

    &:hover {
      color: colors.$color-gray-8;
    }

    &:focus {
      outline: none;
    }
  }

  .expandButtonIcon {
    width: 10px;
    height: 7px;
  }
}

.modal {
  width: 628px;
  .content {
    margin: 0 24px;
  }
  button + button {
    margin-left: 8px;
  }

  .section {
    padding: 40px 0;
    display: grid;
    grid-template: auto / 1fr 1fr;
    gap: 16px 8px;
    align-items: end;
  }

  .section:not(:last-child) {
    border-bottom: 1px solid colors.$color-gray-4;
  }

  &.threeColumns {
    width: calc(628px + 286px);
    .section {
      grid-template: auto / 1fr 1fr 1fr;
    }
  }

  &.fourColumns {
    width: calc(628px + 286px * 2);
    .section {
      grid-template: auto / 1fr 1fr 1fr 1fr;
    }
  }
}

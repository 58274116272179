@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  flex-flow: nowrap;
  font-size: 14px;
  font-weight: 400;
}

.T1 {
  .control {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #595959;
    padding: 0 4px;
    user-select: none;

    &:hover {
      opacity: 0.85;
    }

    &:disabled {
      color: colors.$color-gray-6;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }

    svg {
      transition: 0.3s all;
    }

    .firsPageElement {
      width: 1px;
      height: 7px;
      margin-right: 1px;
      background-color: currentColor;
      transition: 0.3s all;
    }
  }

  .controlIcon {
    width: 5px;
    height: 8px;
  }

  .pageInput {
    @include mixins.input();
    height: 32px;
    width: 60px;
    font-size: 14px;
    margin-left: 8px;
  }
}

.T2 {
  .control {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #2c2653;
    padding: 0 4px;
    user-select: none;

    &:hover {
      opacity: 0.85;
    }

    &:disabled {
      color: colors.$color-gray-6;
    }

    svg {
      transition: 0.3s all;
    }

    .firsPageElement {
      width: 1px;
      height: 7px;
      margin-right: 1px;
      background-color: currentColor;
      transition: 0.3s all;
    }
  }

  .controlIcon {
    width: 5px;
    height: 8px;
  }

  .pageInput {
    @include mixins.input-t2();
    height: 32px;
    width: 60px;
    font-size: 14px;
  }
}

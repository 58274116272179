@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  .form {
  }

  .errorMessage {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    color: colors.$color-red-4;
    white-space: pre-wrap;
  }

  .frame {
    margin-top: 24px;
  }

  .eye {
    color: colors.$color-gray-6;
  }

  .showPassword {
    .eye {
      color: colors.$color-text-2;
    }
  }

  .passwordField {
    max-width: 50%;
  }
}

.subTabs {
  display: flex;

  .tab {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 20px;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: transparent;
    }
  }

  .tab + .tab {
    margin-left: 64px;
  }

  .activeTab {
    // color: colors.$color-secondary;
    &:after {
      background-color: colors.$color-secondary;
    }
    .tabNumber {
      border-color: colors.$color-secondary;
      background-color: #ffffff;
    }
  }
}

.regDataGrid {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1fr;
  padding: 20px 0 24px;
  gap: 24px;

  .gridCell {
    label {
      display: block;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      color: rgba(26, 34, 59, 0.7);
    }

    span {
    }
  }
}

.enterpriseData {
  padding-top: 24px;
}

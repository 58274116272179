@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.T1 {
  @include mixins.input();
  width: 300px;
  padding: 0 12px 0 32px;
}

.T2 {
  @include mixins.input-t2();
  border: 1px solid #d9b673;
  color: #d9b673;
  width: auto;
  padding: 0 24px 0 42px;

  &:hover:not(:disabled):not([readOnly]),
  &:focus:not(:disabled):not([readOnly]) {
    border-color: darken($color: #d9b673, $amount: 10);
    color: darken($color: #d9b673, $amount: 10);
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.disabled {
    background-color: #f5f5f5;
    color: colors.$color-gray-6;
    border-color: colors.$color-gray-5;
    cursor: auto;
    user-select: none;

    &:hover:not(:disabled):not([readOnly]),
    &:focus:not(:disabled):not([readOnly]) {
      color: colors.$color-gray-6;
      border-color: colors.$color-gray-5;
    }
  }

  .uploadIcon {
    position: absolute;
    left: 16px;
  }

  .input {
    display: none;
  }
}

.fullWidth {
  width: 100%;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  @include mixins.container-t2();
  padding-left: 88px;
  padding-top: 40px;

  .subtitle {
    margin-top: 0;
  }

  .subtitle:not(:first-child) {
    margin-top: 32px;
  }
}

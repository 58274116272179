@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  height: 100%;
  background-color: colors.$color-primary-dark;

  .inner {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 22px 40px;
  }

  .nav {
    align-self: stretch;
    border-left: 1px solid #8986b2;
    margin-left: 40px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    &:after {
      content: '';
      height: 1px;
      position: absolute;
      left: 40px;
      right: 0;
      background-color: #8986b2;
      // transform: translateY(-50%);
      top: 50%;
    }
  }

  .navRow {
    flex: 1 0 50%;
    display: flex;
    align-items: center;

    a,
    a:visited,
    a:active {
      color: inherit;
    }
  }

  .navRow:first-child {
    font-size: 14px;
    color: #a2a3a3;
    padding-bottom: 3px;
    a {
    }

    a + a {
      margin-left: 25px;
    }

    .oldVersion {
      margin-left: auto;
      color: colors.$color-secondary;
    }
  }

  .navRow:last-child {
    font-size: 15px;
    color: #fff;
    padding-top: 3px;
    .navLink {
    }

    .navLink + .navLink {
      margin-left: 30px;
    }
  }

  .support {
    // display: grid;
    // grid-template-columns: repeat(2, max-content);
    // gap: 0 8px;
    margin-left: auto;
    font-size: 12px;
    line-height: 16px;
    align-self: self-end;
    margin-top: 3px;

    .brackets {
      text-transform: lowercase;
    }

    span {
      white-space: nowrap;
    }

    .mail,
    .phone {
      // text-decoration: underline;
      font-weight: 700;
    }
  }

  .langToggle {
    margin-left: 30px;
  }

  .logButton {
    margin-left: auto;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.pagination {
  display: flex;
  flex-flow: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #595959;

  .item {
    padding: 0 4px;
    user-select: none;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .itemButton {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &:hover {
      color: #262626;
    }

    &.active {
      border: 1px solid #262626;
      background: #f5f5f5;
      cursor: auto;
    }
  }

  .control {
    svg {
      transition: 0.3s all;
    }

    .firsPageElement {
      width: 1px;
      height: 7px;
      margin-right: 1px;
      background-color: currentColor;
      transition: 0.3s all;
    }

    &.disabled {
      pointer-events: none;
      color: colors.$color-gray-6;
    }

    &.prev {
      // order: -1;
    }

    &.next {
      // order: 20;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  @include mixins.modal-container();
  height: 56px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
  padding-right: 56px;
  padding-top: 3px;
  border-bottom: 1px solid rgba(26, 34, 59, 0.16);
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  &.modal {
    width: 1042px;

    .container {
      @include mixins.modal-container();
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

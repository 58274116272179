@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  padding: 6em 0 10em;

  &.overlay {
    @include mixins.coverdiv();
    background: rgba(255, 255, 255, 0.6);
    z-index: vars.$z-loaderOverlay;
  }

  &.noMargins {
    padding: 0;
  }

  &.small {
    font-size: 6px;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: load7 1s infinite ease-in-out;
}
.loader {
  color: colors.$color-gray-5;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@keyframes load7 {
  0%,
  90%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  45% {
    box-shadow: 0 2.5em 0 0;
  }
}

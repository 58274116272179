@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  .content {
    display: flex;
    padding: 0 24px;
    align-items: center;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    max-height: 100px;
    gap: 16px;
  }

  button {
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 40px;
  }

  .button {
    display: block;
    flex-shrink: 0;
    min-width: 185px;
    height: auto;
  }

  .egovLink {
    padding: 0;
    button {
      height: 100%;
      width: 100%;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: block;

  .errorMessage {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    color: colors.$color-red-4;
  }

  .errorIcon {
    color: colors.$color-red-4;
    position: absolute;
    right: -34px;
    top: calc(min(49%, 20px));
    transform: translateY(-50%);
    margin-left: 12px;
    vertical-align: top;
    // z-index: 50;
  }

  &.errorInsideIcon {
    .errorIcon {
      width: 18px;
      height: 18px;
      right: 4px;
      top: 50%;
    }
  }
}

.inline {
  display: flex;
  align-items: center;
}

.label {
  flex-shrink: 0;
}

.inner {
  position: relative;
}

.innerFullWidth {
  flex-grow: 1;
}

.topMargin:not(:first-child) {
  margin-top: 16px;
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 700px;

  .content {
    padding-bottom: 12px;
    overflow-y: auto;
    max-height: 600px;
  }

  .header {
  }

  .invitationRow {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  .invitationRowHeader {
    margin-bottom: 12px;
    font-weight: 700;
  }

  .column1 {
    width: 400px;
  }
  .column2,
  .column3 {
    width: 120px;
    text-align: center;
  }

  .allBtn {
    font-size: 12px;
    line-height: 24px !important;
    margin: 0 2px;
    outline: none;
    white-space: nowrap;
  }

  .list {
    padding-left: 17px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.select {
  width: 200px;
  min-width: 200px;

  &.small {
    .select__control {
      height: 32px;
      min-height: 32px;
      font-size: 14px;
    }
  }

  &.waitingOptions {
    .select__control {
      pointer-events: none;
    }
  }

  .select__value-container {
    color: #fff;
    padding: 0;
    flex-wrap: nowrap;
  }

  .select__indicator {
    padding: 7px;
    svg {
      fill: #bfbfbf;
      width: 14px;
      height: 14px;
    }
  }

  .select__single-value {
    margin-left: 1px;
    margin-right: 0;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__control--menu-is-open {
    z-index: 20;

    svg {
      transform: rotateZ(180deg);
    }
  }

  .select__menu {
    @include mixins.shadow1();
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    z-index: 25;
    width: var(--menu-width, 100%);
    &:has(.select__menu-notice--loading) {
      width: 100%;
    }
  }

  .select__menu-list {
    @include mixins.custom-scroll();
    padding-bottom: 0;
    padding-top: 0;
    // max-height: 300px;
    //border-top: 1px solid rgba(102, 102, 128, 0.4);
  }

  // select__menu-notice--loading

  .select__option {
    min-height: 32px;
    background-color: transparent;
    font-size: 14px;
    line-height: 24px;
    color: #595959;
    padding: 4px 14px;
    margin-bottom: 0;
    width: var(--menu-width);

    &:hover {
      background: #f5f5f5;
    }
  }

  .select__option--is-disabled {
    color: colors.$color-gray-6;
    cursor: not-allowed;
  }

  // .select__option--is-selected {
  //   color: #fff;
  //   display: none;
  // }

  &.select--error {
    .select__control {
      border: 1px solid colors.$color-danger;
    }
    .select__single-value {
      color: colors.$color-danger;
    }
  }

  .select__multi-value {
    background: #f6f7fc;
    border: 1px solid rgba(26, 34, 59, 0.24);
    border-radius: 6px;
    color: rgba(26, 34, 59, 0.4);
    svg {
      width: 16px;
      height: 16px;
    }
    .select__multi-value__label {
      font-size: 14px;
      line-height: 28px;
      padding: 0 0 0 8px;
    }
    .select__multi-value__remove {
      transition: background-color 0.3s;
      &:hover {
        background: rgba(26, 34, 59, 0.1);
        color: rgba(26, 34, 59, 0.4);
      }
    }
  }
}

.select--autocomplete {
  .select__control {
    cursor: text;
  }
  .select__indicators {
    display: none;
  }
}

.fullWidth {
  width: 100%;
}

.selectT1 {
  .select__control {
    @include mixins.input();
    display: inline-flex;
    width: 100%;
    padding: 0 12px;
    cursor: pointer;

    &.select__control--is-focused {
      box-shadow: 0px 0px 4px rgba(53, 48, 120, 0.5);
      border: 1px solid #353078;
      outline: none;
    }

    &.select__control--is-disabled {
      background-color: colors.$color-gray-3;
    }
  }

  .select__value-container {
    padding: 2px 0;
  }

  &.hasValue {
    .select__control:not(.select__control--is-disabled) {
      background: colors.$color-light-purple;
    }
  }
}

.selectT2 {
  .select__control {
    @include mixins.input-t2();
    display: inline-flex;
    width: 100%;
    padding: 0 12px;
    min-height: auto;
    cursor: pointer;

    &.select__control--is-focused {
      box-shadow: 0px 0px 4px rgba(53, 48, 120, 0.5);
      border: 1px solid #353078;
      outline: none;
    }

    &.select__control--is-disabled {
      background-color: #e9ebf2;
      color: #5b6176;
    }
    .select__single-value--is-disabled {
      color: #5b6176;
    }
  }

  &.large {
    .select__control {
      height: 64px;
      font-size: 16px;
    }

    .select__option {
      min-height: 64px;
      display: flex;
      align-items: center;
    }
  }

  &.productTable {
    min-width: auto;

    .select__control {
      font-size: 14px;
      line-height: 19px;
      border-radius: 0;
      border-color: transparent;
      height: 29px;
      padding: 0 9px 0 8px;
      // align-items: flex-start;
    }
    .select__single-value {
      padding-bottom: 1px;
    }

    .select__control--is-disabled {
      background-color: #f6f7fc;
    }

    .select__option {
      white-space: normal;
      min-height: 18px;
      line-height: 16px;
    }

    .select__menu-list {
      max-height: 230px;
    }
  }

  &.selectError {
    .select__control {
      border: 1px solid colors.$color-danger;
      color: colors.$color-danger;
      background: transparentize($color: colors.$color-danger, $amount: 0.95);

      &:hover,
      &:hover:not(:disabled):not([readOnly]) {
        border-color: colors.$color-danger;
      }

      // &:focus,
      // &:focus:not(:disabled):not([readOnly]) {
      //   border-color: colors.$color-danger;
      //   box-shadow: 0px 0px 4px #{transparentize(colors.$color-danger, 0.4)};
      // }
    }

    .select__control--is-focused {
      box-shadow: 0px 0px 4px #{transparentize(colors.$color-danger, 0.4)};
      border-color: colors.$color-danger;
      outline: none;
    }
  }

  &.selectShort {
    .select__control {
      padding: 0 0 0 9px;
    }
  }
}

.select-mui-container {
  position: relative;

  .select__control {
    height: 64px;
  }

  .select__option {
    min-height: 64px;
    display: flex;
    align-items: center;
  }

  .select__value-container {
    padding-top: 19px;
  }

  .select-mui-label {
    position: absolute;
    z-index: 22;
    transform: translateY(-50%);
    left: 14px;
    top: 50%;
    color: rgba(26, 34, 59, 0.7);
    font-size: 16px;
    cursor: text;
    transition: all 0.15s ease-in;
    pointer-events: none;
  }

  .select-mui.hasValue ~ .select-mui-label,
  .select-mui:has(> .select__control--menu-is-open) ~ .select-mui-label {
    top: 18px;
    font-size: 13px;
  }
}

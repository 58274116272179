@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  background: colors.$color-primary;
  color: colors.$color-text-2;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  min-height: 100%;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  padding-left: vars.$taxpayer-aside-width;
  @include mixins.transition(padding, color);

  .header {
    height: vars.$taxpayer-header-height;
    z-index: vars.$z-header;
    position: fixed;
    right: 0;
    left: vars.$taxpayer-aside-width;
    top: 0;
  }

  .content {
    flex: 1 0 auto;
    position: relative;
    background-color: colors.$color-surface-1;
    // padding-bottom: 24px;
    padding-top: vars.$taxpayer-header-height;
    width: 100%;
  }

  .aside {
    flex-shrink: 0;
    position: fixed;
    background: colors.$color-primary;
    top: 0;
    left: 0;
    bottom: 0;
    color: #fff;
    width: vars.$taxpayer-aside-width;
    @include mixins.transition(width);
    z-index: vars.$z-aside;
  }
}

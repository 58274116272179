@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .inner {
    margin-top: 40px;
    padding: 0 0 0 16px;
  }

  .actionButtons {
    button + button {
      margin-left: 12px;
    }

    .buttonGap {
      margin-left: 32px;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding-top: 20px;

  .filters {
    margin-bottom: 16px;
  }

  .pagination {
    margin-top: 8px;
  }

  .headerButton + .headerButton {
    margin-left: 8px;
  }

  .loader {
    margin-top: 80px;
  }

  .actionButtons {
    margin-bottom: 4px;
  }

  .actionButton {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  @include mixins.container();
  padding-top: 20px;

  .inner {
    margin-top: 24px;
    padding-left: 16px;
  }

  .goodAmount {
    margin: 32px 0 8px;
  }
}

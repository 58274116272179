@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-color: colors.$color-gray-5;
  border: 1px solid colors.$color-gray-4;
}

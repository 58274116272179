@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding: 16px 20px;
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr 20px;
  grid-template-rows: 20px 18px;
  grid-auto-flow: column;
  gap: 0 16px;
  border-bottom: 1px solid var(--Supplementary-50, #f1f2f6);
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  .cell {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cell:nth-child(odd) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    abbr {
      font-weight: normal;
    }
  }

  .cell:nth-child(even) {
    font-size: 14px;
    color: rgba(26, 34, 59, 0.55);
    line-height: 18px;
  }

  .cell:nth-child(n + 5) {
    text-align: right;
  }

  .actionColumn {
    grid-row: 1 / 3;
    position: relative;
  }

  .menuButton {
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(26, 34, 59, 0.55);
    width: 20px;
    height: 20px;
  }

  .menuButtonIcon {
    transform: translate(-50%, -50%) rotate(-90deg);
    width: 100%;
    height: 100%;
  }

  .moreButtons {
    position: absolute;
    right: -6px;
    top: 22px;
    z-index: 100;
    width: max-content;
    min-width: 200px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(38, 38, 38, 0.16), 0px 1px 2px 0px rgba(38, 38, 38, 0.04);
    padding: 0;
    margin: 0;
  }

  &:last-child {
    .moreButtons {
      top: auto;
      bottom: 44px;
    }
  }

  .moreButton {
    height: 36px;
    padding: 0 16px 0 8px;
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(26, 34, 59, 0.08);
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: colors.$color-gray-2;
    }
  }

  .moreButton:last-child {
    border-bottom: none;
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  .header {
    padding: 15px 0 0 15px;
  }

  .logoLink {
    display: block;
  }

  .navigation {
    margin-top: 46px;
  }

  .logout {
    margin-top: 24px;
  }
}

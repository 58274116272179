@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  --warningColor: colors.$color-text-2;
  opacity: 1;
  @include mixins.transition(opacity);
  min-height: 293px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 12px 20px;
    border-bottom: 1px solid #f1f2f6;
  }

  &.visualyHidden {
    opacity: 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }

  .date {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;
    align-items: flex-end;

    span:first-child {
      font-weight: 700;
    }

    span:nth-child(2) {
      color: rgba(26, 34, 59, 0.55);
    }
  }

  section {
    padding: 12px 20px;
    border-bottom: 1px solid #f1f2f6;
    &:last-child {
      border-bottom: none;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    height: 26px;
    align-items: center;

    .label {
      font-size: 14px;
      line-height: 20px;
      position: relative;
      display: inline-block;
    }

    .value {
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      abbr {
        font-weight: normal;
        margin-left: 4px;
        font-size: 16px;
      }
    }
  }

  .row + .row {
    margin-top: 8px;
  }

  .infoIcon {
    display: none;
    position: absolute;
    transform: translateY(-50%);
    top: 42%;
    right: -30px;
    color: var(--warningColor);
  }

  &.warning {
    --warningColor: #ff9500;
    .infoIcon {
      display: block;
    }
  }

  &.crossWarning {
    --warningColor: #ff3b30;
    .infoIcon {
      display: block;
    }
  }
}

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 48px;
  background-color: #fff;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: vars.$z-letterTabs;

  .tab {
    width: 48px;
  }

  .link,
  .button {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    @include mixins.transition(background-color);
    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      // background-color: red;
    }
  }

  .letter {
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 32px;
    font-size: 20px;
    font-weight: 700;
    background-color: transparent;
    border-radius: 50%;
    @include mixins.transition(background-color);
    overflow: hidden;
  }

  .tab.active .letter {
    background-color: colors.$color-secondary;
    color: #fff;
  }

  .decoration {
    position: absolute;
    top: 0;
    left: 100%;
    width: 3px;
    height: 40px;
    background-color: colors.$color-secondary;
    transform: translateY(-50%);
    // @include mixins.transition(top);
    transition: top 0.3s ease-out;
  }

  .badge {
    position: absolute;
    right: 2px;
    top: 0;
    background-color: colors.$color-red-4;
    box-shadow: none;
  }
}

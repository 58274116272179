@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/vars';

.wrapper {
  padding: 0;

  .topHeader {
    padding: 16px 24px;
    margin-bottom: 16px;
  }

  .content {
    padding: 0 24px;
  }
}
